import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Verify from "../views/Verify.vue";
import Error from "../views/Error.vue";
import Pay from "../views/Pay.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Verify",
    component: Verify,
    meta: {
      title: "验证页面",
      keepAlive: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "注册页",
      keepAlive: false,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "主页",
      keepAlive: true,
    },
  },
  {
    path: "/pay",
    name: "Pay",
    component: Pay,
    meta: {
      title: "支付页",
      keepAlive: false,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    meta: {
      title: "错误页",
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
