<template>
  <div class="home">
    <v-user-card></v-user-card>
    <v-my-divider title="我的会员卡"></v-my-divider>
    <v-card-list></v-card-list>
  </div>
</template>

<script>
import userCard from "@/components/user/Card.vue";
import cardList from "@/components/card/List.vue";
import MyDivider from "@/components/common/MyDivider.vue";
import { closeLoading } from "@/service/toast";

export default {
  name: "Home",
  components: {
    "v-user-card": userCard,
    "v-card-list": cardList,
    "v-my-divider": MyDivider,
  },
  created() {
    closeLoading();
  },
};
</script>
<style lang="scss" scoped></style>
