<template>
  <div class="error">
    <van-empty
      class="custom-image"
      :image="require('@/assets/wx.jpg')"
      :description="msg"
    />
  </div>
</template>

<script>
import { closeLoading } from "@/service/toast";

export default {
  name: "error",
  data() {
    return {
      msg: "请在微信中打开",
    };
  },
  mounted() {
    closeLoading();
  },
};
</script>

<style lang="scss" scoped>
.custom-image .van-empty__image {
  width: 12rem;
  height: 10rem;
}
</style>
