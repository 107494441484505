<template>
  <div class="register">
    <div class="app-logo">
      <van-image round width="8rem" height="5rem" :src="logoUrl" />
    </div>
    <div class="app-form">
      <van-cell-group :border="false">
        <van-field
          type="tel"
          v-model="phone"
          label="手机号"
          center
          clearable
          left-icon="phone-o"
          placeholder="注册手机号"
          maxlength="11"
        />
        <van-field
          type="number"
          v-model="code"
          label="验证码"
          center
          left-icon="closed-eye"
          placeholder="请输入验证码"
          maxlength="6"
        >
          <template #button>
            <v-sms-button :phone="phone"></v-sms-button>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="app-active">
      <van-button
        round
        color="linear-gradient(to right, #7232dd, #ee0a24)"
        block
        @click="bindMember()"
      >
        绑定会员
      </van-button>
    </div>
  </div>
</template>

<script>
import SmsButton from "@/components/common/SmsButton.vue";
import { toastMessage } from "@/service/toast.js";
import { post } from "@/service/httpService.js";

export default {
  name: "register",
  components: {
    "v-sms-button": SmsButton,
  },
  data() {
    return {
      phone: "",
      code: "",
      logoUrl: require("@/assets/logo.jpg"),
    };
  },
  methods: {
    /**
     * 绑定会员
     */
    bindMember() {
      post("/app/api/vip/bind", {
        phone: this.phone,
        code: this.code,
        openId: this.$store.state.openid,
      }).then(
        (res) => {
          if (res.code === 0) {
            this.$router.replace("home");
          } else {
            toastMessage(res.message);
          }
        },
        (err) => {
          toastMessage(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  margin: 2rem;

  .app-logo {
  }

  .app-form {
    margin-top: 3rem;

    .van-cell-group {
      border: 1px #eeeeee solid;
      border-radius: 0.5rem;

      .van-field {
        height: 4rem;
      }
    }
  }

  .app-active {
    margin-top: 3.5rem;
  }
}
</style>
