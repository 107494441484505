import { Toast } from "vant";
import store from "../store/index.js";

export function showLoading(msg = "加载中") {
  if (!store.state.loading) {
    Toast.loading({
      message: msg,
      forbidClick: true,
      loadingType: "spinner",
      duration: 0,
      overlay: true,
    });
    store.commit("showLoading");
  }
}

export function closeLoading() {
  if (store.state.loading) {
    Toast.clear();
    store.commit("hideLoading");
  }
}

export function toastMessage(msg = "提示信息") {
  Toast.fail({
    message: msg,
  });
}
