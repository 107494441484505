<template>
  <div class="Pay">
    <div style="height: 2rem"></div>
    <div class="barcode-box">
      <div class="pay-card-number">
        会员卡号:&nbsp;{{ this.vipInfo.number }}
      </div>
      <div class="pay-tip">请向商家出示您的付款码</div>
      <div class="code-qr">
        <canvas ref="qrcode"></canvas>
      </div>
      <div class="code-bar">
        <svg ref="barcode"></svg>
      </div>
      <div class="pay-time">
        <span>{{ second }}</span
        >&nbsp;秒后自动刷新付款码
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import { toastMessage } from "@/service/toast.js";
import { post } from "@/service/httpService.js";

export default {
  name: "Pay",
  data() {
    return {
      second: 60,
      timer: null,
      vipInfo: {},
    };
  },
  created() {
    this.vipInfo = this.$store.state.middleData;
  },
  mounted() {
    this.refreshPayCode();
    this.cutDown();
  },
  methods: {
    barcode(value) {
      JsBarcode(this.$refs.barcode, value, {
        displayValue: false, //是否在条形码下方显示文字
        textPosition: "bottom", //设置文本的垂直位置
        background: "#ffffff", // 设置条形码的背景
        lineColor: "#000000", // 设置条和文本的颜色。
        fontSize: 13,
      });
    },
    qrCode(value) {
      QRCode.toCanvas(this.$refs.qrcode, value, function(error) {
        if (error) {
          toastMessage(error);
        }
      });
    },
    cutDown() {
      this.timer = setInterval(() => {
        this.second--;
        if (this.second === 0) {
          this.second = 60;
          this.refreshPayCode();
        }
      }, 1000);
    },
    refreshPayCode() {
      post("/app/api/vip/card/password", {
        id: Number(this.vipInfo.id),
      })
        .then((res) => {
          if (res.code === 0) {
            this.barcode(res.data);
            this.qrCode(res.data);
          } else {
            toastMessage(res.message);
          }
        })
        .catch((err) => {
          toastMessage(err);
        });
    },
  },
  destroyed() {
    this.$store.commit("resetMiddleData");
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.barcode-box {
  margin: 0 auto;
  width: 95%;

  .pay-card-number {
    color: goldenrod;
    font-weight: bold;
  }

  .pay-tip {
    color: #6e6e6e;
    line-height: 3rem;
  }

  .pay-time {
    color: #8e8e8e;

    span {
      color: orangered;
      font-weight: bold;
    }
  }

  .code-qr {
    margin: 0 auto;
    width: 12rem !important;
    height: 12rem !important;

    canvas {
      width: 12rem !important;
      height: 12rem !important;
    }
  }
}
</style>
