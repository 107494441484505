<template>
  <div class="MyDivider">
    <van-divider>
      {{ title }}
    </van-divider>
  </div>
</template>

<script>
export default {
  name: "MyDivider",
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.MyDivider {
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>

