<template>
  <div class="card-list">
    <div class="card-box" v-for="item in list" :key="item.id">
      <div class="card" @click="myCard(item)">
        <div class="left">
          <van-image
            round
            width="3rem"
            height="3rem"
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
          />
        </div>
        <div class="right">
          <div class="card-num">
            卡号:&nbsp;{{ item.number }}
          </div>
          <div class="card-num">
            品牌:&nbsp;{{ item.area }}
          </div>
          <div class="card-num">
            积分:&nbsp;{{ item.integral }}&nbsp;&nbsp;&nbsp;&nbsp;余额:&nbsp;{{ item.amount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post } from "@/service/httpService.js";
import { toastMessage } from "@/service/toast.js";

export default {
  name: "card-list",
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getVipCardList();
  },
  methods: {
    myCard(obj) {
      this.$store.commit("setMiddleData", obj);
      this.$router.push({ path: "pay" });
    },
    getVipCardList() {
      post("/app/api/vip/card/list", {
        openId: this.$store.state.openid
      })
        .then((res) => {
          if (res.code === 0) {
            this.list = res.data;
          } else {
            this.list = [];
            toastMessage(res.message);
          }
        })
        .catch((err) => {
          toastMessage(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-list {
  padding-left: 1rem;
  padding-right: 1rem;

  .card-box {
    height: 8rem;

    .card {
      border-radius: 1rem;
      height: 7rem;
      background: #eeeeee;
      display: flex;

      .left {
        padding-top: 2rem;
        text-align: center;
        width: 6rem;
      }

      .right {
        padding-top: 1rem;
        text-align: left;
        flex: 1;
      }
    }
  }
}
</style>
