import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  // 状态
  state: {
    loading: false,
    openid: "oERKQ1D6pa-XDmFJoEJvG1jJoSN8",
    middleData: {},
  },
  // 更新状态
  mutations: {
    showLoading(state) {
      if (!state.loading) {
        state.loading = true;
      }
    },
    hideLoading(state) {
      if (state.loading) {
        state.loading = false;
      }
    },
    setOpenid(state, value) {
      state.openid = value;
    },
    setMiddleData(state, value) {
      state.middleData = value;
    },
    resetMiddleData(state) {
      state.middleData = {};
    },
  },
  //类似计算属性,改变state会触发getters方法
  getters: {},
  // 可异步触发mutations改变state
  actions: {},
  modules: {},
});
