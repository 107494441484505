<template>
  <div class="SmsButton">
    <van-button
      size="small"
      round
      type="info"
      v-if="smsDisable"
      :disabled="smsDisable"
    >
      {{ second }}&nbsp;秒
    </van-button>
    <van-button
      size="small"
      round
      type="info"
      v-else
      :disabled="smsDisable"
      @click="clickSend()"
    >
      验证码
    </van-button>
  </div>
</template>

<script>
import { RegPhoneNumber } from "@/utils/reg.js";
import { toastMessage } from "@/service/toast.js";
import { post } from "@/service/httpService.js";

export default {
  name: "SmsButton",
  props: ["phone"],
  data() {
    return {
      smsDisable: false,
      second: 10,
      timer: null,
    };
  },
  methods: {
    clickSend() {
      if (RegPhoneNumber(this.phone)) {
        this.sendSms();
      } else {
        toastMessage("不是有效手机号");
      }
    },
    // 发送短信
    sendSms() {
      post("/app/api/sms/verify", {
        phone: this.phone,
      }).then(
        (res) => {
          if (res.code === 0) {
            toastMessage(res.data);
            this.smsDisable = true;
            this.cutDown();
          } else {
            toastMessage(res.message);
          }
        },
        (err) => {
          toastMessage(err);
        }
      );
    },
    // 倒计时
    cutDown() {
      this.timer = setInterval(() => {
        this.second--;
        if (this.second < 1) {
          this.smsDisable = false;
          this.second = 10;
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
  destroyed() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.SmsButton {
  .van-button {
    width: 5rem;
    text-align: center;
  }
}
</style>
