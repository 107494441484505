import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { post } from "./service/httpService.js";
import Vant from "vant";
import { Lazyload } from "vant";
import "vant/lib/index.css";

Vue.config.productionTip = false;
//定义全局变量
Vue.prototype.$post = post;
Vue.use(Vant);
Vue.use(Lazyload);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
