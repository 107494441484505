<template>
  <div class="verify">
    <van-empty
      class="custom-image"
      :image="require('@/assets/wx.jpg')"
      :description="msg"
    />
  </div>
</template>

<script>
import { showLoading, closeLoading, toastMessage } from "@/service/toast.js";
import { post } from "@/service/httpService.js";

export default {
  name: "verify",
  data() {
    return {
      msg: "授权中",
    };
  },
  created() {
    showLoading();
    this.isWx();
  },
  methods: {
    /**
     * 判断是否微信
     */
    isWx() {
      const ua = navigator.userAgent.toLowerCase();
      const isWeixin = ua.indexOf("micromessenger") !== -1;
      if (isWeixin) {
        // 获取临时授权码
        this.getUrlCode();
      } else {
        this.$router.replace("error");
      }
    },
    /**
     * 获取用户临时授权码
     */
    getUrlCode() {
      const query = this.$route.query;
      if (query.code) {
        // 获取用户openid
        this.getUserOpenid(query.code);
      } else {
        this.msg = "授权码错误";
        toastMessage("授权码错误");
      }
    },
    /**
     * 获取用户openid
     */
    getUserOpenid(code) {
      post("/app/api/wechat/getUserOpenID", {
        code: code,
      }).then(
        (res) => {
          if (res.code === 0) {
            this.$store.commit("setOpenid", res.data);
            this.openidIsExist();
          } else {
            this.msg = res.message;
            toastMessage(res.message);
            closeLoading();
          }
        },
        (err) => {
          toastMessage(err);
          closeLoading();
        }
      );
    },
    /**
     * 判断openid是否存在
     */
    openidIsExist() {
      const openid = this.$store.state.openid;
      post("/app/api/wechat/openIdIsExist", {
        openId: openid,
      }).then(
        (res) => {
          if (res.code === 0) {
            this.$router.replace("home");
          } else if (res.code === 5) {
            toastMessage(res.message);
            this.$router.replace("register");
          } else {
            this.msg = res.message;
            toastMessage(res.message);
            closeLoading();
          }
        },
        (err) => {
          toastMessage(err);
          closeLoading();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-image .van-empty__image {
  width: 10rem;
  height: 10rem;
}
</style>
