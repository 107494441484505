import axios from "axios";
import { appConfig } from "../../public/product.config";

axios.defaults.timeout = appConfig.timeOut;
// axios.defaults.baseURL = appConfig.serviceUrl;

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json",
      "Server-uuid": appConfig.uuid,
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 返回拦截器
axios.interceptors.response.use(
  (response) => {
    // 处理
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 封装POST方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
