<template>
  <div class="card-box">
    <div class="set-box"></div>
    <div class="user-card" :style="cardUrl">
      <div class="set-box"></div>
      <div class="user-img">
        <van-image
          round
          width="5rem"
          height="5rem"
          lazy-load
          :src="info.headimgurl"
        >
          <template v-slot:error>加载中</template>
        </van-image>
      </div>
      <div class="user-title">{{ info.nickname }}</div>
    </div>
  </div>
</template>

<script>
import { post } from "@/service/httpService.js";
import { toastMessage, showLoading, closeLoading } from "@/service/toast.js";
export default {
  name: "user-card",
  data() {
    return {
      info: {
        headimgurl: "",
        nickname: "",
      },
      imageUrl: require("@/assets/logo.jpg"),
      cardUrl: {
        background: "url(" + require("@/assets/bg_card.png") + ") repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
  created() {
    showLoading();
  },
  mounted() {
    this.getWeChatUserInfo();
  },
  methods: {
    getWeChatUserInfo() {
      post("/app/api/wechat/getUserInfo", {
        openId: this.$store.state.openid,
      })
        .then((res) => {
          if (res.code === 0) {
            this.info = res.data;
          } else {
            toastMessage(res.message);
          }
          closeLoading();
        })
        .catch((err) => {
          closeLoading();
          toastMessage(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  height: 12rem;

  .set-box {
    height: 1rem;
  }

  .user-card {
    text-align: center;
    margin: 0 auto;
    height: 10rem;
    width: 90%;
    border-radius: 1rem;

    .user-img {
      margin: 0 auto;
      height: 6rem;
    }

    .user-title {
      height: 3rem;
      color: goldenrod;
    }
  }
}
</style>
